/* eslint-disable */
import React from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Rating from '@material-ui/lab/Rating';
import useStyles from './styles';
import { CallMissedSharp, PlaceSharp } from '@material-ui/icons';

function PlaceDetails({ place, selected, refProp }) {
  const classes = useStyles();

  // console.log({ refProp });
  // console.log({ selected });
  if (selected)
    refProp?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  return (
    <>
      {place.name && (
        <Card elevation={6}>
          <CardMedia
            style={{ height: 350 }}
            image={
              place.photo
                ? place.photo?.images.large.url
                : 'https://picsum.photos/id/42/400/300'
            }
            title={place.name}
          />
          <CardContent>
            <Typography gutterBottom variant='h5'>
              {place.name}
            </Typography>
            <Box display='flex' justifyContent='space-between' my={2}>
              <Rating name='read-only' value={Number(place.rating)} readOnly />
              <Typography component='legend'>
                {place.num_reviews} review{place.num_reviews > 1 && 's'}
              </Typography>
            </Box>
            <Box display='flex' justifyContent='space-between'>
              <Typography component='legend'>Price</Typography>
              <Typography gutterBottom variant='subtitle1'>
                {place.price_level}
              </Typography>
            </Box>
            <Box display='flex' justifyContent='space-between'>
              <Typography component='legend'>Ranking</Typography>
              <Typography gutterBottom variant='subtitle1'>
                {place.ranking}
              </Typography>
            </Box>
            {place?.awards?.map((award) => {
              <Box
                display='flex'
                justifyContent='space-between'
                my={1}
                alignItems='center'
              >
                <img src={award.images.small} alt='' />
                <Typography variant='subtitle1' color='textSecondary'>
                  {award.display_name}
                </Typography>
              </Box>;
            })}
            {place?.cuisine?.map(({ name }) => (
              <Chip
                key={name}
                size='small'
                label={name}
                className={classes.chip}
              />
            ))}
            {place.address && (
              <Typography
                gutterBottom
                variant='body2'
                color='textSecondary'
                className={classes.subtitle}
              >
                <LocationOnIcon /> {place.address}
              </Typography>
            )}
            {place.phone && (
              <Typography
                gutterBottom
                variant='body2'
                color='textSecondary'
                className={classes.subtitle}
              >
                <PhoneIcon /> {place.phone}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              size='small'
              color='primary'
              onClick={() => window.open(place.web_url, '_blank')}
            >
              Trip Advisor
            </Button>
            <Button
              size='small'
              color='primary'
              onClick={() => window.open(place.website, '_blank')}
            >
              Website
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
}

export default PlaceDetails;
